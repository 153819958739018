<!--
 * @Description: 凭证
 * 
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-19 23:43:55
 * @FilePath: /golden_cicada/src/pages/views/user/order/mods/voucher.vue
-->
<template>
  <div class="negative-popup">
    <div class="negative" @click.stop>
      <div class="negative-text">上传付款凭证</div>
      <div class="negative-content">
        <div v-if="img" class="voucher-view">
          <img :src="img" alt="" />
        </div>
        <div v-else class="voucher-view" @click="handleSelectFile">
          <div class="voucher-image">
            <img src="../../../../../assets/icon/upload.png" alt="" />
          </div>
          <div class="voucher-text">点击上传付款凭证</div>
        </div>
      </div>
      <div class="negative-btn">
        <div class="btn confirm" @click="handleConfirm">确认</div>
        <div class="btn" @click="shows = false">取消</div>
      </div>
      <div class="negative-close" @click="shows = false">
        <img src="../../../../../assets/icon/close.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { uploadImage, getToken } from "../../../../../utils/common";
import httpConfig from "../../../../../utils/index";

export default {
  props: {
    show: Boolean,
    data: {
      type: Object,
    },
  },
  data() {
    return {
      files: null,
      img: null,
    };
  },
  computed: {
    shows: {
      get() {
        return this.show;
      },
      set(newValue) {
        this.$emit("update:show", newValue);
      },
    },
  },
  methods: {
    async handleSelectFile() {
      const files = await uploadImage();
      const reader = new FileReader();
      reader.readAsDataURL(files);
      reader.onloadend = () => {
        this.files = files;
        this.img = reader.result;
      };
    },
    async handleConfirm() {
      if (!this.files) {
        this.$message.error("请先选择需要上传的凭证");
        return;
      }
      const formData = new FormData();
      formData.append("file", this.files);
      formData.append("orderno", this.data.orderno);
      const url = `${httpConfig.URL_PREFIX_API[1]}api/customer/userProduct/uploadPayedImg`;
      const resp = await fetch(url, {
        method: "post",
        headers: {
          token: getToken(),
        },
        body: formData,
      });
      const res = await resp.json();
      if (res.code === 200) {
        this.$message.success("上传成功");
        this.shows = false;
        this.$emit("update");
      } else {
        this.$message.error(res.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.negative-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.7);
  display: flex;
  z-index: 100;
  align-items: center;
  justify-content: center;
  .negative {
    width: calc(510px);
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    &-text {
      font-size: 30px;
      color: #000000;
      text-align: center;
      margin-top: 35px;
    }
    &-content {
      display: flex;
      justify-content: center;
      justify-content: center;
      margin-top: 45px;
      .voucher-view {
        width: 313px;
        height: 179px;
        background: #e0e6ff;
        border-radius: 6px;
        cursor: pointer;
        & > img {
          width: 313px;
          height: 179px;
        }
        .voucher-image {
          width: 61px;
          height: 61px;
          margin: auto {
            top: 42px;
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
        .voucher-text {
          text-align: center;
          margin-top: 25px;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
          font-weight: normal;
          color: #7c7f97;
        }
      }
    }
    &-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 45px 0 50px 0;
      .btn {
        width: 140px;
        height: 37px;
        background-color: #e0e4f6;
        margin-right: 16px;
        &:last-child {
          margin-right: 0;
        }
        font-size: 16px;
        font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
        font-weight: normal;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;
      }
      .confirm {
        background-color: #4e6bef;
        color: #fff;
      }
    }
    &-close {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
