<!--
 * @Description: 订单
 * 
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-19 23:00:38
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/user/order/index.vue
-->
<template>
  <div>
    <div class="app-wrapper wrapper-view">
      <div class="order-hand">
        <div class="wrapper-title">套餐详情</div>
        <!-- <div class="order-btn">
          <div class="btn" @click="handlePages('UserAccount')">
            查看套餐信息
          </div>
        </div> -->
      </div>
      <el-table
        :data="tableData"
        stripe
        max-height="300"
        style="width: 100%; margin-top: 21px"
      >
        <el-table-column
          prop="status"
          label="我的套餐"
          align="center"
          width="100"
        >
          <template slot-scope="{ row }">
            <div
              :class="[
                'cell-tag',
                {
                  'tag-warning': row.title.includes('高级'),
                  'tag-primary': row.title.includes('旗舰'),
                },
              ]"
            >
              {{ row.title }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="orderno"
          label="订单编号"
          show-overflow-tooltip
          align="center"
          min-width="120"
        >
        </el-table-column>
        <el-table-column
          prop="created_at"
          label="订购时间"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="efficacy_at"
          label="生效时间"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="expired_at"
          label="失效时间"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column prop="opts" label="操作" align="center" width="400">
          <template slot-scope="{ row }">
            <el-button
              size="mini"
              type="text"
              :disabled="!['1', '2'].includes(`${row.state}`)"
              @click="handleOpts('payment', row)"
              >继续支付</el-button
            >
            <el-button
              size="mini"
              type="text"
              :disabled="!['4', '5', '6', '8'].includes(`${row.state}`)"
              @click="handleOpts('escalate', row)"
              >套餐升级</el-button
            >
            <el-button
              size="mini"
              type="text"
              :disabled="['8', '9', '10'].includes(`${row.state}`)"
              @click="handleOpts('refund', row)"
              >退款申请</el-button
            >
            <el-button size="mini" type="text" @click="handleOpts('apply', row)"
              >发票申请</el-button
            >
            <el-button
              size="mini"
              type="text"
              :disabled="!['1', '2', '3'].includes(`${row.state}`)"
              @click="handleOpts('voucher', row)"
              >上传付款凭证</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-show="page.total > 0"
        :total="page.total"
        :page.sync="page.page"
        :limit.sync="page.size"
        @pagination="getList()"
      />
    </div>
    <div class="app-wrapper wrapper-view" style="margin-top: 17px">
      <div class="wrapper-title">套餐变更记录</div>
      <el-table
        :data="tableData2"
        stripe
        max-height="300"
        style="width: 100%; margin-top: 21px"
      >
        <el-table-column prop="status" label="变更前套餐" align="center">
          <template slot-scope="{ row }">
            <div
              :class="[
                'cell-tag',
                {
                  'tag-warning': row.before_title.includes('高级'),
                  'tag-primary': row.before_title.includes('旗舰'),
                },
              ]"
            >
              {{ row.before_title }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="status1" label="变更后套餐" align="center">
          <template slot-scope="{ row }">
            <div
              :class="[
                'cell-tag',
                {
                  'tag-warning': row.after_title.includes('高级'),
                  'tag-primary': row.after_title.includes('旗舰'),
                },
              ]"
            >
              {{ row.after_title }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="changed_at"
          label="变更时间"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="efficacy_at"
          label="生效时间"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="expired_at"
          label="失效时间"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
      </el-table>
    </div>

    <!-- 发票申请单 -->
    <ApplyView
      v-if="apply.show"
      :show.sync="apply.show"
      v-bind="apply"
      @update="getList(1)"
    />

    <!-- 上传凭证 -->
    <VoucherView
      v-if="voucher.show"
      :show.sync="voucher.show"
      v-bind="voucher"
      @update="getList(1)"
    />

    <!-- 升级套餐 -->
    <EscalateView
      v-if="escalate.show"
      :show.sync="escalate.show"
      v-bind="escalate"
      @update="getList(1)"
    />

    <!-- 退款申请 -->
    <RefundView
      v-if="refund.show"
      :show.sync="refund.show"
      v-bind="refund"
      @update="getList(1)"
    />

    <!-- 签署合同 -->
    <SignView
      v-if="sign.show"
      :show.sync="sign.show"
      v-bind="sign"
      @update="getList(1)"
    />

    <!-- 付款 -->
    <PaymentView
      v-if="payment.show"
      :show.sync="payment.show"
      v-bind="payment"
      @update="getList(1)"
    />
  </div>
</template>

<script>
import ApplyView from "./mods/apply.vue";
import VoucherView from "./mods/voucher.vue";
import EscalateView from "./mods/escalate.vue";
import RefundView from "./mods/refund.vue";
import SignView from "@/components/sign";
import PaymentView from "@/components/payment";
import { HTTP } from "../../../../utils/request";

const URL = {
  list: "api/customer/userProduct/list",
  changeLog: "api/customer/userProduct/changeLog",
};

export default {
  components: {
    ApplyView,
    SignView,
    PaymentView,
    VoucherView,
    EscalateView,
    RefundView,
  },
  data() {
    return {
      tableData: [],
      tableData2: [],
      isLoadingLog: false,
      apply: {
        show: false,
        data: null,
      },
      sign: {
        show: false,
        data: null,
      },
      payment: {
        show: false,
        data: null,
      },
      voucher: {
        show: false,
        data: null,
      },
      escalate: {
        show: false,
        data: null,
      },
      refund: {
        show: false,
        data: null,
      },
    };
  },
  mounted() {
    this.getList(1);
    this.getChangeLog();
  },
  methods: {
    async getList(page = this.page.page, size = this.page.size) {
      this.isLoading = true;
      const res = await HTTP({
        url: URL.list,
        method: "get",
        data: {
          page,
          limit: size,
        },
      });
      let { total, per_page, current_page, data } = res || {};
      this.page = { total, page: current_page, size: +per_page };
      this.tableData = data || [];
      this.isLoading = false;
    },
    async getChangeLog() {
      this.isLoadingLog = true;
      const res = await HTTP({
        url: URL.changeLog,
        method: "get",
      });
      this.tableData2 = res || [];
      this.isLoadingLog = false;
    },

    // 跳转
    handlePages(name) {
      this.$router.push({
        name,
      });
    },

    handleOpts(type, row = null) {
      switch (type) {
        case "apply":
          this.apply = {
            show: true,
            data: {
              ...row,
            },
          };
          break;
        case "sign":
          this.sign = {
            show: true,
            data: {
              ...row,
            },
          };
          break;
        case "payment":
          this.payment = {
            show: true,
            data: {
              ...row,
            },
          };
          break;
        case "voucher":
          this.voucher = {
            show: true,
            data: {
              ...row,
            },
          };
          break;
        case "escalate":
          this.escalate = {
            show: true,
            data: {
              ...row,
            },
          };
          break;
        case "refund":
          this.refund = {
            show: true,
            data: {
              ...row,
            },
          };
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.order-hand {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .order-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    .btn {
      padding: 10px 22px;
      border-radius: 4px;
      background-color: #4e6bef;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
      font-weight: normal;
      color: #ffffff;
      line-height: 14px;
      margin-left: 12px;
      cursor: pointer;
    }
  }
}
.cell-tag {
  margin: 0 auto;
  width: 55px;
  height: 21px;
  background: #acc7e7;
  font-size: 12px;
  font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
  font-weight: normal;
  color: #ffffff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tag-warning {
  background: #efa44b;
}

.tag-primary {
  background: #9f9ced;
}
</style>
