<!--
 * @Description: 申请退款
 * 
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-20 00:08:57
 * @FilePath: /golden_cicada/src/pages/views/user/order/mods/refund.vue
-->
<template>
  <div class="negative-popup">
    <div class="negative" @click.stop>
      <div class="negative-text">退款申请</div>
      <div class="negative-content">
        <div class="refund-text">
          是否确认申请退款？点击确认后，您将进入退款流程，客服<br />
          将在7个工作日内联系您处理相关事宜。
        </div>
      </div>
      <div class="negative-btn">
        <div class="btn" @click="handleConfirm">确定提交</div>
        <div class="btn confirm" @click="shows = false">我再想想</div>
      </div>
      <div class="negative-close" @click="shows = false">
        <img src="../../../../../assets/icon/close.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "../../../../../utils/request";

const URL = {
  applyRefund: "api/customer/userProduct/applyRefund",
};
export default {
  props: {
    show: Boolean,
    data: {
      type: Object,
    },
  },
  computed: {
    shows: {
      get() {
        return this.show;
      },
      set(newValue) {
        this.$emit("update:show", newValue);
      },
    },
  },
  methods: {
    async handleConfirm() {
      try {
        await HTTP({
          url: URL.applyRefund,
          method: "post",
          data: {
            orderno: this.data.orderno,
          },
        });
        this.$message.success("操作成功");
        this.shows = false;
        this.$emit("update");
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.negative-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.7);
  display: flex;
  z-index: 100;
  align-items: center;
  justify-content: center;
  .negative {
    width: calc(580px);
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    &-text {
      font-size: 30px;
      color: #000000;
      text-align: center;
      margin-top: 35px;
    }
    &-content {
      display: flex;
      justify-content: center;
      justify-content: center;
      margin-top: 45px;
      .refund-text {
        font-size: 20px;
        font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
        font-weight: normal;
        color: #999999;
        line-height: 30px;
        text-align: center;
      }
    }
    &-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 45px 0 50px 0;
      .btn {
        width: 140px;
        height: 37px;
        background-color: #e0e4f6;
        margin-right: 16px;
        &:last-child {
          margin-right: 0;
        }
        font-size: 16px;
        font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
        font-weight: normal;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;
      }
      .confirm {
        background-color: #4e6bef;
        color: #fff;
      }
    }
    &-close {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
