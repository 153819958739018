<!--
 * @Description: 申请单
 * 
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-19 23:24:41
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/user/order/mods/apply.vue
-->
<template>
  <div class="negative-popup">
    <div class="negative" @click.stop>
      <div class="negative-text">发票申请单</div>
      <div class="negative-content">
        <div class="content-data">
          <div class="content-input">
            <input type="text" v-model="header" placeholder="发票抬头" />
          </div>
          <div class="content-input">
            <input type="text" v-model="taxnumber" placeholder="企业税号" />
          </div>
          <div class="content-input">
            <input type="text" v-model="mailbox" placeholder="邮箱地址" />
          </div>
          <div class="content-input">
            <input type="text" v-model="mobile" placeholder="联系电话" />
          </div>
        </div>
      </div>
      <div class="negative-btn">
        <div class="btn confirm" @click="handleConfirm">确认</div>
        <div class="btn" @click="shows = false">取消</div>
      </div>
      <div class="negative-tip">
        备注：发票将在5个工作日内发送到邮箱，请注意查收
      </div>
      <div class="negative-close" @click="shows = false">
        <img src="../../../../../assets/icon/close.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { getCookie } from "../../../../../utils/common";
import { HTTP } from "../../../../../utils/request";

const URL = {
  applyInvoice: "api/customer/userProduct/applyInvoice",
  info: "api/customer/user/info",
  operationInfo: "/api/operation/user/info",
};

export default {
  props: {
    show: Boolean,
    data: {
      type: Object,
    },
  },
  data() {
    return {
      header: "",
      taxnumber: "",
      mailbox: "",
      mobile: "",
    };
  },
  computed: {
    shows: {
      get() {
        return this.show;
      },
      set(newValue) {
        this.$emit("update:show", newValue);
      },
    },
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    async getInfo() {
      const role = getCookie("role");
      const res = await HTTP({
        url: URL[role === "operation" ? "operationInfo" : "info"],
        method: "get",
      });
      this.mobile = res?.mobile || "";
    },
    async handleConfirm() {
      if (!this.header) {
        this.$message.error("发票抬头不能为空");
        return;
      }
      if (!this.taxnumber) {
        this.$message.error("企业税号不能为空");
        return;
      }
      if (!this.mailbox) {
        this.$message.error("邮箱不能为空");
        return;
      }
      if (!this.mobile) {
        this.$message.error("联系电话为空");
        return;
      }
      try {
        await HTTP({
          url: URL.applyInvoice,
          method: "post",
          data: {
            orderno: this.data.orderno,
            header: this.header,
            taxnumber: this.taxnumber,
            mailbox: this.mailbox,
            mobile: this.mobile,
          },
        });
        this.$message.success("操作成功");
        this.shows = false;
        this.$emit("update");
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.negative-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.7);
  display: flex;
  z-index: 100;
  align-items: center;
  justify-content: center;
  .negative {
    width: calc(510px);
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    &-text {
      font-size: 30px;
      color: #000000;
      text-align: center;
      margin-top: 35px;
    }
    &-content {
      display: flex;
      justify-content: center;
      margin-top: 45px;
      .content-input {
        width: calc(272px);
        height: 37px;
        border: 1px solid #d9e0ed;
        border-radius: 4px;
        background-color: #f8faff;
        margin-bottom: 27px;
        &:last-child {
          margin-bottom: 0;
        }
        input {
          width: 100%;
          height: 100%;
          border: none;
          border-radius: 4px;
          padding: 0 10px;
          background-color: #f6f9ff;
        }
      }
      .content-tip {
        font-size: 14px;
        font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
        font-weight: normal;
        color: #7c7f97;
        margin-top: 25px;
      }
    }
    &-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 45px;
      .btn {
        width: 140px;
        height: 37px;
        background-color: #e0e4f6;
        margin-right: 16px;
        &:last-child {
          margin-right: 0;
        }
        font-size: 16px;
        font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
        font-weight: normal;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;
      }
      .confirm {
        background-color: #4e6bef;
        color: #fff;
      }
    }
    &-tip {
      font-size: 12px;
      font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
      font-weight: normal;
      color: #b4b4b4;
      margin-top: 20px;
      margin-bottom: 50px;
      text-align: center;
    }
    &-close {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
